





import { Option } from "@/models/class/option.class";
import { EnumBooleanValue } from "@/types/Boolean";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectBoolean extends Vue {
  @Prop({ required: false, default: "", type: String })
  value!: string;

  options: Array<Option> = [
    {
      key: 0,
      label: this.$t("lbl_yes").toString(),
      value: "Y" as EnumBooleanValue,
    },
    {
      key: 1,
      label: this.$t("lbl_no").toString(),
      value: "N" as EnumBooleanValue,
    },
  ];

  onChange(e: EnumBooleanValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
